.messenger-container {
  display: flex;
}

.user-list {
  background-color: lightgray;
  height: 480px;
 /* Set fixed height */
  width: 260px;
  border-right: 1px solid #ccc;
  padding: 10px;
  overflow-y: auto; /* Enable vertical scrolling */
}

.scroll-container {
  height: 480px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
}
.userss{
  /* max-height: 500px; */
  border-radius: 2px;
  background-color: lightgray;
  padding:2px;
 /* height: fit-content; */
}

.user-list h2 {
  margin-top: 0;
}

.user-list input[type="text"] {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
}
.userss input[type="text"] {
  width: 93%;
  margin-bottom: 10px;
  padding: 5px;
  border:1px solid lightgray;
  border-radius: 5px;
}

.user-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-list li {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.user-list li.active {
  background-color: #f0f0f0;
}

.user-list li:hover {
  background-color: #f0f0f0;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-details {
  flex-grow: 1;
}

.user-name {
  font-weight: bold;
}

.user-last-message {
  font-size: 0.8rem;
}

.message-section {
  flex-grow: 1;
  padding: 10px;
}

.message-section h2 {
  margin-top: 0;
}

.message-box {
  height: 420px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.message {
  margin-bottom: 10px;
}

.message-content {
  display: flex;
}
.message-content-admin {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  background-color: #bacef1;
  padding: 10px;
  border-radius: 10px;
  margin-left: 10px;
  color: black;
}

.message-content img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-content p {
  margin: 0;
}
.compose-message{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: 'center';

}

.compose-message textarea {
  width: calc(100% - 20px);
  padding: 5px;
  height: 50px;
  margin-right: 10px;
  border:1px solid lightgray;
  border-radius: 5px;
}

.compose-message button {
  width: 80px;
  height: 50px;
  padding: 5px;
  border: none;
  background-color: rgb(46, 103, 183);
  color: white;

}
.message-section1{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border:1px solid lightgray
}

.message-content {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  background-color: #ECEFF1;
  padding: 10px;
  border-radius: 10px;
  margin-left: 10px;
}

.info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sent-time {
  font-size: 12px;
  color: #777;
}

.status {
  font-size: 12px;
  color: #777;
}

.typing {
  font-style: italic;
  color: #777;
  margin-left: 50px;
}

.input {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 10px;
  resize: none; /* Prevent resizing */
  font-size: 14px;
  line-height: 1.5;
  width: calc(100%); /* Adjust width as needed */
  min-height: 40px;
  max-height: 80px; /* Limit height to allow scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
}

.input:focus {
  outline: none;
  border-color: indigo; /* Change border color on focus */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .message-content {
      max-width: 70%;
  }
}

@media screen and (max-width: 576px) {
  .message-content {
      max-width: 60%;
  }

  .typing {
      margin-left: 20px;
  }
}
.message-container {
  padding-right: 20px; /* Add padding to ensure scrollbar doesn't overlay content */
}

.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.user-message {
  justify-content: flex-start;
}

.admin-message {
  justify-content: flex-end;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
}

