body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.errorContainer{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.errorContainer button{
  width: 20%;
  padding: 10px;
  border: none;
  background-color: #2ec2e2;
  border-radius: 50px;
  color: white;
  font-weight: bold;
  cursor: pointer;

}

.errorTitle{
  font-size: 28px
}

html{
  scroll-behavior: smooth
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ffffff00;
  border-radius: 0.375rem;
  box-shadow: white;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.form-control:focus {
  color: #8898aa;
  background-color: #fff;
  border-color: rgba(50, 151, 211, 0);
  outline: 0;
  box-shadow: none;
}
.form-control:focus {
  color: #8898aa;
  background-color: #fff;
  border-color: rgba(50, 151, 211, 0);
  outline: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}
.inside-table {
  color: #8898aa;
  background-color: #fff;
  border-color: rgba(50, 151, 211, 0);
  outline: 0;
  /* box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02); */
}
.inside-table {
  display: block;
  width: 100%;
  height: 35px;
  padding: 5px;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  /*background-color: #fff;*/
  background-clip: padding-box;
  border:none;
  borderBottom: 1px solid black;
  /*border-radius: 0.375rem;*/
  /*box-shadow: white;*/
  /*box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);*/
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.link-table:hover{
   background-color: #FFFFFF;
}
.link-mtable.MuiIconButton-root:hover {
  background-color: rgb(255, 255, 255);
}

.payment-transaction {
  .title {
    font-weight: bold;
    color: black;
  }
}



@import '~antd/dist/antd.css';
